import React from "react";
import { Image } from "react-bootstrap";
import img1 from "../../assets/participate-1.svg";
import img2 from "../../assets/participate-2.svg";
import img3 from "../../assets/participate-3.svg";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./participate.css";

const Participate = () => {
  return (
    <>
      <Header />
      <div className="participate-main">
        <div className="participate">
          <div>
            <h1>
              School Level:{" "}
              <span style={{ color: "red" }}> Unleash Your Potential</span>{" "}
            </h1>
            <p>
              Here, you have the opportunity to showcase your skills and compete
              against some of the brightest minds in your school. Push your
              boundaries, embrace the thrill of healthy competition, and let
              your passion for excellence shine. This is your chance to unleash
              your potential and make a mark in your school.
            </p>
          </div>
          <div>
            <Image src={img1}></Image>
          </div>
        </div>
        <div className="participate">
          <div>
            <Image src={img2}></Image>
          </div>
          <div>
            <h1>
              National Level:{" "}
              <span style={{ color: "red" }}> Rise above rest </span>{" "}
            </h1>
            <p>
              The competition becomes fiercer and the stakes are higher. This is
              the stage where the best of the best gather to prove their mettle.
              Rise above the rest, challenge yourself, and strive for greatness.
              Let your dedication, knowledge, and perseverance set you apart as
              you compete on a national scale. It's time to leave a lasting
              impression and inspire others with your achievements.
            </p>
          </div>
        </div>
        <div className="participate">
          <div>
            <h1>
              International Level:{" "}
              <span style={{ color: "red" }}> A World of Excellence </span>{" "}
            </h1>
            <p>
              This is the pinnacle of achievement, where you'll meet the most
              brilliant minds from around the world. Prepare to be amazed,
              challenged, and inspired by the global talent gathered here.
              Embrace the diversity of cultures, perspectives, and ideas as you
              compete for international recognition. This is your chance to
              shine on a global stage and become a true ambassador of
              excellence.
            </p>
          </div>
          <div>
            <Image src={img3}></Image>
          </div>
        </div>
      </div>
      <div className="rewardSys">
        <div className="rewards">
          <h1>Rewards and Awards</h1>
          <div>
            <div>1</div>
            <div>2</div>
            <div>3</div>
          </div>
        </div>

        <div className="parLine"></div>
        <h3>
          Get ready to unleash your potential, rise above the rest, and embrace
          a world of excellence.
          <br />
          <span style={{ color: "#17A1FA" }}>Welcome to the Olympiad!</span>
        </h3>
      </div>

      <Footer />
    </>
  );
};

export default Participate;
