import React from "react";
import SideBar from "./SideBar.js";
import Header from "./Header.jsx";
import { Carousel } from "react-bootstrap";
import "./certificate.css";
// import greenBg from "../../assets/greenBG.svg";

const certificate = () => {
  return (
    <div>
      <div>
        <SideBar style={{ background: "white" }} />
      </div>
      <div className="bg-support">
        <Header />

        <Carousel className="carousel">
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="https://marketplace.canva.com/EAFIEvneNCM/1/0/1600w/canva-golden-elegant-certificate-of-appreciation-0bN-aLORS9U.jpg"
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="https://marketplace.canva.com/EAFIEvneNCM/1/0/1600w/canva-golden-elegant-certificate-of-appreciation-0bN-aLORS9U.jpg"
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="https://marketplace.canva.com/EAFIEvneNCM/1/0/1600w/canva-golden-elegant-certificate-of-appreciation-0bN-aLORS9U.jpg"
              alt="Third slide"
            />
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
};

export default certificate;
