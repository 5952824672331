import React, { useState } from "react";
import Questions from "../../components/Questions/Questions";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import puzzle from "../../assets/puzzle.svg";
import rocket from "../../assets/rocket.svg";
import school_logos from "../../assets/school_abt_logos.svg";
import kapil from "../../assets/kapil.jpeg";
import "./AboutPage.css";
import Dropdown from "../../components/LearnHeader/Dropdown.js";

const AboutPage = () => {
  const [part1, setPart1] = useState(true);
  const [part2, setPart2] = useState(false);
  const [part3, setPart3] = useState(false);
  const [part4, setPart4] = useState(false);

  const [Class, setClass] = useState("");
  const buttonHandler1 = (e) => {
    setPart1(true);
    setPart2(false);
    setPart3(false);
    setPart4(false);
  };
  const buttonHandler2 = (e) => {
    setPart1(false);
    setPart2(true);
    setPart3(false);
    setPart4(false);
  };
  const buttonHandler3 = (e) => {
    setPart1(false);
    setPart2(false);
    setPart3(true);
    setPart4(false);
  };
  const buttonHandler4 = (e) => {
    setPart1(false);
    setPart2(false);
    setPart3(false);
    setPart4(true);
  };
  const data = [
    {
      id: "1",
      class: "class1",
      unit: "Number System & Sequence and Series",
      chapter: "Rational and Irrational Numbers Arithmetic Progression",
      noOfQuestions: 4,
      marks: 2.5,
      totalMarks: 9,
    },
    {
      id: "1",
      class: "class1",
      unit: "unit 2",
      chapter: "chapter 2",
      noOfQuestions: 3,
      marks: 3,
      totalMarks: 9,
    },
    {
      id: "1",
      class: "class2",
      unit: "unit 3",
      chapter: "chapter 3",
      noOfQuestions: 3,
      marks: 3,
      totalMarks: 9,
    },
    {
      id: "1",
      class: "class3",
      unit: "unit 3",
      chapter: "chapter 3",
      noOfQuestions: 3,
      marks: 3,
      totalMarks: 9,
    },
    {
      id: "1",
      class: "class4",
      unit: "unit 3",
      chapter: "chapter 3",
      noOfQuestions: 3,
      marks: 3,
      totalMarks: 9,
    },
    {
      id: "1",
      class: "class5",
      unit: "unit 3",
      chapter: "chapter 3",
      noOfQuestions: 3,
      marks: 3,
      totalMarks: 9,
    },
    {
      id: "1",
      class: "class6",
      unit: "unit 3",
      chapter: "chapter 3",
      noOfQuestions: 3,
      marks: 3,
      totalMarks: 9,
    },
  ];
  const classHandler = (e) => {
    console.log(e.target.value);
  };

  return (
    <>
      <Header />
      <br />
      <br />
      <div className="about_top">
        <div>
          <h1>
            {" "}
            <span style={{ fontWeight: "bold" }}>
              The International Mathematical
            </span>{" "}
            <span style={{ color: "#898989" }}>
              "Unleash Your Mathematical Potential: Compete in the Math
              Olympiad!"
            </span>{" "}
          </h1>
        </div>
        <div>
          <p style={{ fontWeight: "500" }}>
            The International Mathematical Olympiad (IMO) is the Mathematics
            Competition for High School students and is held annually in a
            different country.
          </p>
        </div>
      </div>
      <br />
      <div className="puzzle">
        <img src={puzzle} alt="" />
      </div>

      {/* <span className="center_line"></span> */}
      {/* <div className="about_top together">
        <div>
          <h1>Together we are strong</h1>
        </div>
        <div>
          <p>
            Our family is always getting bigger, but we all work towards one
            goal, to help each and every student touching the sky with their
            KNOWLEDGE.
          </p>
          <div>
            The International Mathematical Olympiad (IMO) is the World
            Championship Mathematics Competition for High School students and is
            held annually in a different country. The first IMO was held in 1959
            in Romania, with 7 countries participating. It has gradually
            expanded to over 100 countries from 5 continents.
          </div>
        </div>
      </div> */}

      <p className="about-para">
        Maths Olympiad Foundation caters to all students from classes 1-12 at
        the National and International level. It will be conducting examinations
        annually every year. The pattern of IMO-2023 is designed keeping in mind
        the NCERT syllabus. The questions in this exam will be in MCQ format
        which requires to be resolved within the required time frame. Students
        participating in the IMO-2023 will understand mathematical concepts
        precisely and learn to prepare for future competitive examinations.
      </p>
      <div>
        <div id="sylla" className="button-level">
          <button
            className={part1 && "addColor"}
            onClick={(e) => buttonHandler1(e)}
          >
            Structure of the Olympiad
          </button>
          <button onClick={(e) => buttonHandler2(e)}>
            Chapter Wise Marking Scheme
          </button>
          <button onClick={(e) => buttonHandler3(e)}>
            Prizes, Dates and venue
          </button>
          <button onClick={(e) => buttonHandler4(e)}>
            Zone/State Categorization
          </button>
        </div>
        {part1 ? (
          <div className="part1-css">
            <h3>Important information</h3>
            <ul className="textType">
              <li>
                The registration fee for all classes is Rs. 150 to participate
                in the International Maths Olympiad.
              </li>
              <li>All students will receive a participation certificate.</li>
              <li>
                Winners will be awarded Medals and Certificates at the School
                Level.
              </li>
              <li>
                International winners will receive the prestigious "Ramanujan
                Student Award," along with Certificates and attractive prizes.
              </li>
              <li>
                Innovative Mathematicians will be honored with "The Grand
                Ramanujan Award" for their outstanding performance in the field
                of Mathematics. (Please file your nomination in the attached
                form)
              </li>
              <li>
                The Olympiad Coordinator of the school will be presented with a
                Certificate of Appreciation.
              </li>
              <li>
                All participants of Class 1 and 2 will be given level-wise
                medals for encouragement.
              </li>
              <li>
                A grand award ceremony will be held in New Delhi to felicitate
                the International winners.
              </li>
            </ul>
            <h3>Levels of Exams</h3>

            <strong>Level 1</strong>
            <div className="textType">
              All the students of classes 1st to 12th are eligible to take part
              in Level 1.
            </div>
            <br />
            <strong>Eligibility for Level 2</strong>
            <div className="textType">
              Top 1000 Olympiad rank holders between 4 and 1003 with minimum
              marks of 50% and above. Class 1st Rank holders with minimum 75%
              marks and 100 participation in the respective subject from the
              school.
            </div>
            <br />
            <br />
            <span className="center_line"></span>
            <br />
            <strong>Examination</strong>
            <div className="textType">
              The Olympiad is organized in the respective schools of the
              participants during the school hours.
              <br />
              The structure of the Olympiad stands as the following:
              <br />
              There will be a different question paper for each class. All the
              questions are objective-type with no negative marking for wrong
              answers.
            </div>
            <br />
            <div>
              <table class="table table-hover">
                <thead style={{ borderRadius: "10px" }} class="table-success">
                  <tr>
                    <th scope="row">Classes</th>
                    <th>1-2</th>
                    <th>3-5 </th>
                    <th>6-12</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">No. of Questions</th>
                    <td>20</td>
                    <td>40</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <th scope="row">Duration(in minutes) </th>
                    <td>60 </td>
                    <td>60 </td>
                    <td>60 </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h6>Total Marks : 100</h6>
            <br />
            <br />
            <p className="textType">
              The details of the manner in which the test is to be conducted
              will be sent with the question papers. For classes, 3rd onward OMR
              answer sheets will be provided.
              <br />
              <br />
              The questions will be of objective type in nature with multiple
              choice answers. There is no negative marking.
            </p>
          </div>
        ) : part2 ? (
          <div className="textType" style={{ margin: "8%" }}>
            <h3>Marking Scheme:</h3>
            <strong>For Classes 1 & 2:</strong>
            <ul>
              <li>Total number of questions: 20</li>
              <li>Duration of examination: 60 minutes</li>
              <li>Each question carries 5 marks</li>
            </ul>
            <strong>For Classes 3 to 5:</strong>
            <ul>
              <li>Total number of questions: 40</li>
              <li>Duration of examination: 60 minutes</li>
              <li>Questions 1 to 20 carry 2 marks each</li>
              <li>Questions 21 to 40 carry 3 marks each</li>
            </ul>
            <strong>For Classes 6 to 12:</strong>
            <ul>
              <li>Total number of questions: 50</li>
              <li>Duration of examination: 60 minutes</li>
              <li>Questions 1 to 20 carry 1 mark each</li>
              <li>Questions 21 to 30 carry 2 marks each</li>
              <li>Questions 31 to 50 carry 3 marks each</li>
            </ul>
            10% of total students participating in the 1st round shall be
            qualified for the next 2nd round. Students not qualified for the
            second round will be awarded a Participation Certificate.
            <br />
            <br />
            The Principal of participating schools who register 500 or more
            students will be awarded the Golden Plate, 300 or more students with
            the Silver Plate, and 200 or more participants with the Bronze
            Plate. The Olympiad Co-ordinator shall be awarded the Excellency
            Award. All the decisions taken by the Maths Olympiad Foundation
            management shall be final and binding.
            <br />
            <br />
            <h3>Syllabus:</h3>
            <p>
              <strong>Class 1:</strong> In Class 1, students learn about shapes
              and space, numbers name (1-50), addition, subtraction (two
              digits), data handling, time, measurement, patterns, grouping, how
              many, odd one out, spatial understanding, money, lengths, weights
              and comparison. They also engage in higher-order thinking skills
              (HOTS) questions.
            </p>
            <p>
              <strong>Class 2:</strong> Class 2 covers topics such as numbers
              name (1-100), addition, subtraction (two digits), counting in
              group, data handling, geometrical shapes, measurement, patterns,
              grouping, how many, odd one out, spatial understanding, money,
              time &amp; calendar, temperature, pictographs, lengths, weights
              and comparison, capacity, and HOTS questions.
            </p>
            <p>
              <strong>Class 3:</strong> In Class 3, students study geometrical
              shapes, numerals, number names and number sense (4-digit numbers),
              computation operations, fractions, length, weight, capacity,
              temperature, time, money, geometry, data handling, and HOTS
              questions.
            </p>
            <p>
              <strong>Class 4:</strong> Class 4 focuses on numerals and number
              names, number sense (more than 4-digit numbers), computation
              operations, fractions, length, weight, capacity, time, money,
              geometry, perimeter of various shapes, symmetry, conversions, data
              handling, and HOTS questions.
            </p>
            <p>
              <strong>Class 5:</strong> In Class 5, students learn about
              numerals, number names and number sense (7 and 8-digit numbers),
              computation operations, fractions and decimals, measurement of
              length, weight, capacity, time, temperature and money,
              conversions, geometrical shapes and solids, angles, perimeter of
              various shapes, area of rectangle and square, symmetry, data
              handling, and HOTS questions.
            </p>
            <p>
              <strong>Class 6:</strong> Class 6 covers subjects such as knowing
              our numbers, whole numbers, playing with numbers, basic
              geometrical ideas, understanding elementary shapes, integers,
              fractions, decimals, data handling, mensuration, algebra, ratio
              and proportion, symmetry, practical geometry, and HOTS questions.
            </p>
            <p>
              <strong>Class 7:</strong> In Class 7, students study integers,
              fractions and decimals, exponents and powers, algebraic
              expressions, simple linear equations, lines and angles, comparing
              quantities, the triangle and its properties, symmetry, congruence
              of triangles, rational numbers, perimeter and area, data handling,
              visualising solid shapes, and practical geometry.
            </p>
            <p>
              <strong>Class 8:</strong> Class 8 focuses on rational numbers,
              squares and square roots, cubes and cube roots, exponents and
              powers, comparing quantities, algebraic expressions and
              identities, linear equations in one variable, understanding
              quadrilaterals, constructions, mensuration, visualising solid
              shapes, data handling, direct and inverse variations,
              factorisation, introduction to graphs, and playing with numbers.
            </p>
            <p>
              <strong>Class 9:</strong> In Class 9, students learn about real
              numbers, polynomials, linear equations in two variables,
              coordinate geometry, geometry, lines and angles, triangles,
              quadrilaterals, circles, area, surface area and volumes,
              statistics, and probability.
            </p>
            <p>
              <strong>Class 10:</strong> Class 10 covers subjects such as real
              numbers, polynomials, pair of linear equations in two variables,
              quadratic equation, arithmetic progressions, coordinate geometry,
              geometry, triangles, introduction to trigonometry, trigonometric
              identities, height and distances (angle of elevation, angle of
              depression), area related to circles, surface area and volumes,
              statistics, and probability.
            </p>
            <p>
              <strong>Class 11:</strong> In Class 11, students study sets,
              relations and functions, trigonometric functions, principle of
              mathematical induction, complex numbers and quadratic equations,
              linear inequalities, permutations and combinations, binomial
              theorem, sequences and series, straight lines, conic sections,
              introduction to three-dimensional geometry, limits and
              derivatives, mathematical reasoning, statistics, and probability.
            </p>
            <p>
              <strong>Class 12:</strong> Class 12 focuses on relations and
              functions, inverse trigonometric functions, matrices,
              determinants, continuity and differentiability, applications of
              derivatives, integrals, application of integrals, differential
              equations, vectors, three-dimensional geometry, linear
              programming, and probability.
            </p>
            {/* <div className="part2-css">
              <Dropdown onChange={(e) => classHandler(e)} />
              <br />
              <table class="table table-hover">
                <thead style={{ borderRadius: "10px" }} class="table-success">
                  <tr>
                    <th scope="col">Unit</th>
                    <th scope="col">Chapter</th>
                    <th scope="col">No. of Questions</th>
                    <th scope="col">Marks</th>
                    <th scope="col">Total Marks</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Number System & Sequence and Series</td>
                    <td>
                      Rational and Irrational Numbers Arithmetic Progression
                    </td>
                    <td>4</td>
                    <td>2.5</td>
                    <td>9</td>
                  </tr>
                </tbody>
              </table>
            </div> */}
          </div>
        ) : part3 ? (
          <div className="part3-css textType">
            <h3>Prizes (International Level)</h3>
            <p>
              Get ready for an incredible opportunity to showcase your math
              skills and win amazing prizes!
            </p>
            <p>
              1st Prize – An astounding reward of Rs. 5100/- awaits you, along
              with a magnificent trophy and a prestigious Certificate of Merit.
            </p>
            <p>
              2nd Prize – Be prepared to be honored with a thrilling prize of
              Rs. 3100/-, a remarkable trophy, and an esteemed Certificate of
              Merit.
            </p>
            <p>
              3rd Prize – Don't miss your chance to win an impressive prize of
              Rs. 2100/-, an exquisite trophy, and a prestigious Certificate of
              Merit.
            </p>
            <p>
              Participate in the Math Olympiad, and let your brilliance shine on
              the international stage. Embrace the challenge, seize the
              opportunity, and aim for the top prizes!
            </p>
            <br />
            <h3>Important Dates & Venues</h3>
            <p>I. Stage (School Level)</p>
            <p>Examination will be organized in your own school.</p>
            <p>
              The result of the 1st Stage will be declared within 30 days of the
              Exam.
            </p>
            <p>
              Exam Dates: 5 October 2023, 19 October 2023, 1 November 2023, 28
              November 2023
            </p>
            <p>Last Date: 10 September 2023</p>
            <br />
            <p>II. Stage (International/National Level)</p>
            <p>(Only for Classes 3-12)</p>
            <p>Exam Date: 22 December 2023</p>
          </div>
        ) : part4 ? (
          <div className="part4-css">To be added soon!</div>
        ) : (
          ""
        )}
      </div>
      {/* <div className="mission_vision">
        <div className="mission">
          <h3>OUR MISSION</h3>
          <span className="yellow_line"></span>
          <p>
            Our mission is to inspire and motivate students to develop a passion
            for mathematics and to provide them with opportunities to showcase
            their mathematical abilities through participation in math
            Olympiads.
          </p>
        </div>
        <div className="rocket">
          <img src={rocket} alt="" />
        </div>
        <div className="vision">
          <h3>OUR VISION</h3>
          <span className="red_line"></span>
          <p>
            Our vision is to create a world where every student has access to
            quality math education and is empowered to use their mathematical
            skills to make a positive impact on society.
          </p>
        </div>
      </div> */}

      <div style={{ textAlign: "center", marginTop: "100px" }}>
        <h1>
          We have <span style={{ color: "#21794B" }}>1k + SCHOOLS</span>{" "}
          partnered with Us
        </h1>
      </div>
      <br />
      <div style={{ textAlign: "center", marginRight: "100px" }}>
        <img src={school_logos} alt="" />
      </div>
      <div className="founder">
        <div className="founder_img">
          <img src={kapil} alt="" width={"200px"} height={"auto"} />
          <span className="name_founder">
            Kapil
            <br />
            <span className="designation">Founder & CEO</span>
          </span>
        </div>
        <div>
          <p>
            Welcome to the Maths Olympiad Foundation! Our mission is to ignite a
            passion for mathematics and empower students to achieve excellence.
            We provide challenging exams, comprehensive resources, and mentoring
            opportunities to nurture talent and foster critical thinking. Join
            us in unlocking the power of mathematics, exploring new frontiers,
            and shaping a brighter future. Maths Olympiad Foundation - Where
            Mathematical Excellence Begins.
          </p>
        </div>
      </div>
      <span className="center_line"></span>
      <div className="stats">
        <div>
          <p>Schools associated with us in all these years</p>
          <h1>1867+</h1>
        </div>
        <div>
          <p>Olympiads organised by us</p>
          <h1>297+</h1>
        </div>
        <div>
          <p>States in which several school chooses IMO</p>
          <h1>15+</h1>
        </div>
      </div>
      <Questions />
      <Footer />
    </>
  );
};

export default AboutPage;
