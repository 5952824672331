import React from "react";
import "./AboutUs.css";
import aboutUs from "../../assets/about-us.svg";
import olympiads from "../../assets/olympaids.svg";
import { Button, Image } from "react-bootstrap";
import landingAbout from "../../assets/landingAbout.svg";
import landingPar from "../../assets/landingPar.svg";
import arrow from "../../assets/Arrow.svg";

const AboutUs = () => {
  return (
    <div className="about-us">
      {/* <span
        style={{
          position: "relative",
          left: "120px",
          top: "30px",
          width: "6px",
          height: "26px",
          backgroundColor: "red",
          display: "block",
        }}
      ></span>{" "}
      <h3>ABOUT US</h3> */}
      <div className="about-us-section">
        <div>
          <h1 style={{ color: "#1D3F2E", fontWeight: "bold" }}>
            Who are we
            <br />
            and what we do ?
          </h1>
          <p style={{textAlign:'justify'}}>
            The Maths Olympiad Foundation conducts annual examinations for
            students from classes 1 to 12 at the national and international
            levels. These exams go beyond the regular curriculum, challenging
            students to apply problem-solving and logical reasoning skills. The
            foundation organizes exams at different levels, starting from
            school, regional, and national, leading to international
            competitions. The exams serve as a platform to identify and
            recognize talented students in mathematics. The foundation provides
            study materials, resources, and training programs to support
            students in their preparation. By participating in the Maths
            Olympiad exams, students develop critical thinking abilities and a
            deep understanding of mathematics. The foundation aims to foster a
            love for mathematics and nurture young talents, promoting academic
            growth and future career prospects for the students.
          </p>
          <a href="/about">
            <Button variant="danger">Know more -></Button>
          </a>
        </div>
        <div>
          <img src={landingAbout} alt="" />
        </div>
      </div>

      {/* <div className="olympaids">
        <img src={olympiads} alt="" />
      </div> */}
    </div>
  );
};

export default AboutUs;
