import React from "react";
import SideBar from "./SideBar.js";
import { Link } from "react-router-dom";
import classes from "./Tutorial.module.css";
import Header from "./Header.jsx";
import { Row, Col } from "react-bootstrap";
import Tutorial from "./component/Tutorial";
const Tutorials = () => {
  const tutData = [
    {
      id: "1",
      name: "Hello world",
      author: "John Doe",
      // videoUrl:'',
      thumbnail:
        "https://www.practice-olympiad.com/images/what-is-olympiad.jpg",
    },
    {
      id: "2",
      name: "Hello world",
      author: "John Doe",
      // videoUrl:'',
      thumbnail:
        "https://www.practice-olympiad.com/images/what-is-olympiad.jpg",
    },
    {
      id: "3",
      name: "Hello world",
      author: "John Doe",
      // videoUrl:'',
      thumbnail:
        "https://www.practice-olympiad.com/images/what-is-olympiad.jpg",
    },
    {
      id: "4",
      name: "Hello world",
      author: "John Doe",
      // videoUrl:'',
      thumbnail:
        "https://www.practice-olympiad.com/images/what-is-olympiad.jpg",
    },
    {
      id: "5",
      name: "Hello world",
      author: "John Doe",
      // videoUrl:'',
      thumbnail:
        "https://www.practice-olympiad.com/images/what-is-olympiad.jpg",
    },
    {
      id: "6",
      name: "Hello world",
      author: "John Doe",
      // videoUrl:'',
      thumbnail:
        "https://www.practice-olympiad.com/images/what-is-olympiad.jpg",
    },
  ];
  return (
    <div>
      <div>
        <SideBar />
      </div>
      <div>
        <div>
          <Header />
        </div>
        <div className={classes.mainTut}>
          <Link style={{ textDecoration: "none" }} to="/">
            <h4>Tutorials</h4>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/">
            <h4>Mock Test Paper</h4>
          </Link>
        </div>
        <div className={classes.allTutorial}>
          <Row>
            {tutData.map((tutorial) => {
              return (
                <Col key={tutorial.id} sm={12} md={6} lg={4} xl={4}>
                  <Tutorial tutorial={tutorial} />
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Tutorials;
