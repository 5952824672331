import React, { useState, useMemo } from "react";
import SideBar from "./SideBar.js";
import Header from "./Header.jsx";
import classes from "./ClassList.module.css";
import { Link, useParams } from "react-router-dom";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

const ClassList = (props) => {
  const { type } = useParams();
  const defaultMaterialTheme = createTheme();

  const tableColumns1 = [
    // { title: "Enroll. No.", field: "enrollNum" },
    {
      title: "Name of Student",
      field: "studentName",
      // lookup: groupTypeOptionsDataLinks,
      // defaultFilter: ["65"],
      // lookup: { dataLinkTables: JSON.parse(JSON.stringify(dataLinkTables)) },
    },
    { title: "Father/Guardian Name", field: "fatherName" },
    {
      title: "Mail",
      field: "mail",
    },
    {
      title: "Mobile Number",
      field: "mobileNum",
      // lookup: { true: "True", false: "False" },
    },
  ];
  const [tableData, setTableData] = useState([
    {
      // enrollNum: "2144677",
      studentName: "Tushar Singh",
      fatherName: "Manoj",
      mail: "xyz@gmail.com",
      mobileNum: "8927146723",
    },
    {
      // enrollNum: "2144678",
      studentName: "Apoorv",
      fatherName: "Gaurav",
      mail: "xyz@gmail.com",
      mobileNum: "9925146723",
    },
  ]);
  const [state, setState] = useState({
    file: null,
  });
  function handleFile(e) {
    e.preventDefault();
    let f = e.target.files[0];
    setState({ file: f });
    console.log(e.target.files);
    // setPaymentReceipt(e.target.files[0]);
    let file = state.file;
    console.log(state, "state data:");
    console.log(file, "state file:");
    const receipt = new FormData();
    receipt.append("receipt", file);

    axios
      .post("https://api.imo-india.org/school/students/upload", receipt, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.status == 200) {
          toast.success(res.response.data.message);
        }
      })
      .catch((err) => {
        console.log("error--: ");
        console.log(err);
        toast.error(err.response.data.message);
      });
  }

  return (
    <div>
      <div>
        <SideBar />
      </div>
      <div>
        <Header />
        <div className={classes.mainClassList}>
          {/* <h2>List</h2> */}
          <Row className="mt-4">
            <div>
              <Label
                htmlFor="formFile"
                className="form-label"
                style={{
                  background: "#1d3f2e",
                  padding: "7px 13px",
                  color: "white",
                  borderRadius: "7px",
                  marginLeft: "2vw",
                }}
              >
                Import
              </Label>
              <Input
                onChange={handleFile}
                className="form-control"
                type="file"
                id="formFile"
                style={{ visibility: "hidden" }}
              />
            </div>
            <Col lg={12}>
              <ThemeProvider theme={defaultMaterialTheme}>
                <MaterialTable
                  title="Students List"
                  data={tableData}
                  columns={tableColumns1}
                  actions={[
                    {
                      icon: "refresh",
                      tooltip: "Refresh Data",
                      isFreeAction: true,
                    },
                  ]}
                  editable={{
                    onRowAdd: (newRow) =>
                      new Promise((resolve, reject) => {
                        setTableData([...tableData, newRow]);
                        resolve();
                      }),
                    onRowUpdate: (newRow, oldRow) =>
                      new Promise((resolve, reject) => {
                        const updatedData = [...tableData];
                        updatedData[oldRow.tableData.id] = newRow;
                        setTableData(updatedData);
                        resolve();
                      }),
                    onRowDelete: (selectedRow) =>
                      new Promise((resolve, reject) => {
                        const updatedData = [...tableData];
                        updatedData.splice(selectedRow.tableData.id, 1);
                        setTableData(updatedData);
                        resolve();
                      }),
                  }}
                  options={{
                    exportButton: false,
                    exportAllData: false,
                    exportFileName: "Documents",
                  }}
                />
              </ThemeProvider>
            </Col>
          </Row>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default ClassList;
