import React, { useState, useMemo } from "react";
import SideBar from "./SideBar.js";
import Header from "./Header.jsx";
import classes from "./ClassList.module.css";
import { Link, useParams } from "react-router-dom";
import MaterialTable from "material-table";
import axios from "axios";
import { ThemeProvider, createTheme } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import * as XLSX from 'xlsx';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

const StudentData = () => {
  const defaultMaterialTheme = createTheme();

  const tableColumns1 = [
    { title: "Roll No.", field: "rno" },
    { title: "Enroll. No.", field: "enrollNum" },
    {
      title: "Name of Student",
      field: "studentName",
      // lookup: groupTypeOptionsDataLinks,
      // defaultFilter: ["65"],
      // lookup: { dataLinkTables: JSON.parse(JSON.stringify(dataLinkTables)) },
    },
    { title: "Father/Guardian Name", field: "fatherName" },
    {
      title: "Mail",
      field: "mail",
    },
    {
      title: "Mobile Number",
      field: "mobileNum",
      // lookup: { true: "True", false: "False" },
    },
  ];
  const [tableData, setTableData] = useState([
    {
      rno: "1",
      enrollNum: "2144677",
      studentName: "Tushar Singh",
      fatherName: "Manoj",
      mail: "xyz@gmail.com",
      mobileNum: "8927146723",
    },
    {
      rno: "2",
      enrollNum: "2144678",
      studentName: "Apoorv",
      fatherName: "Gaurav",
      mail: "xyz@gmail.com",
      mobileNum: "9925146723",
    },
  ]);
  // const importExcel=(e)=>{
  //   const file = e.target.files[0];
  //   const reader = new FileReader()
  //   reader.onload=(event)=>{
  //     // console.log(event)
  //     const bstr=event.target.result;
  //     const workBook=XLSX.read(bstr,{type:"binary"})

  //     const workSheetName = workBook.SheetNames[0];
  //     const workSheet = workBook.Sheets[workSheetName];
  //     const fileData = XLSX.utils.sheet_to_json(workSheet,{header:1})
  //     console.log(fileData);
  //   }
  //   reader.readAsBinaryString(file)
  // }

  // const [state, setState] = useState({
  //   file: null,
  // });
  // function handleFile(e) {
  //   e.preventDefault();
  //   let f = e.target.files[0];
  //   setState({ file: f });
  //   console.log(e.target.files);
  //   // setPaymentReceipt(e.target.files[0]);
  //   let file = state.file;
  //   console.log(state, "state data:");
  //   console.log(file, "state file:");
  //   const receipt = new FormData();
  //   receipt.append("receipt", file);

  //   axios
  //     .post("https://api.imo-india.org/school/students/upload", receipt, {
  //       headers: {
  //         Authorization: "Bearer " + localStorage.getItem("token"),
  //       },
  //     })
  //     .then((res) => {
  //       if (res.status == 200) {
  //         toast.success(res.response.data.message);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("error--: ");
  //       console.log(err);
  //       toast.error(err.response.data.message);
  //     });
  // }

  return (
    <div>
      <div>
        <SideBar />
      </div>
      <div>
        <Header />
        <div className={classes.mainClassList}>
          {/* <h2>List</h2> */}
          <Row className="mt-4">
            {/* <div>
              <Label
                htmlFor="formFile"
                className="form-label"
                style={{
                  background: "#1d3f2e",
                  padding: "7px 13px",
                  color: "white",
                  borderRadius: "7px",
                  marginLeft: "2vw",
                }}
              >
                Import
              </Label>
              <Input
                onChange={handleFile}
                className="form-control"
                type="file"
                id="formFile"
                style={{ visibility: "hidden" }}
              />
            </div> */}
            <Col lg={12}>
              <ThemeProvider theme={defaultMaterialTheme}>
                <MaterialTable
                  title="Students List"
                  data={tableData}
                  columns={tableColumns1}
                  actions={[
                    {
                      icon: "refresh",
                      tooltip: "Refresh Data",
                      isFreeAction: true,
                    },
                  ]}
                  options={{
                    exportButton: true,
                    // exportAllData: false,
                    exportFileName: "Documents",
                  }}
                />
              </ThemeProvider>
            </Col>
          </Row>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default StudentData;
