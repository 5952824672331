import React from "react";
import { Link } from "react-router-dom";
import Arrow from "../../assets/Arrow.svg";
import Tutorial from "../../assets/tutorials-m.png";
import pencil from "../../assets/pencil.svg";
import Questions from "../Questions/Questions";
import "./Tutorials.css";

const Tutorials = () => {
  return (
    <div className="about-us tut">
      {/* <span
        style={{
          position: "relative",
          left: "120px",
          top: "30px",
          width: "6px",
          height: "26px",
          backgroundColor: "red",
          display: "block",
        }}
      ></span>{" "}
      <h3>Tutorials and Mock Test Papers</h3> */}
      <div className="tut-section">
        <div>
          <h1>
            Get access to
            <br />
            <span style={{ color: "#DD2B1C", fontWeight: "bold" }}>
              {" "}
              unlimited resources
            </span>
          </h1>
          <p>
            Start your preparation with our unlimited tutorials
            <br />
            and mock test papers
          </p>
          <Link to="/results">
            <button>
              <div className="letsCheck">
                <span>LET’S CHECK!</span>
                <img src={Arrow} alt="" />
              </div>
            </button>
          </Link>
        </div>
        <div className="tut-img">
          <img src={Tutorial} alt="" />
        </div>
      </div>
      <Questions />
      <br />
      <br />
      <br />
      <div className="pencil">
        <img src={pencil} alt="" />
        <br />
        <p>
          "Mathematics is not about numbers, equations, computations, or
          algorithms: it is about understanding." - William Paul Thurston
        </p>
        <br />
        <br />
      </div>
    </div>
  );
};

export default Tutorials;
