import React, { useState } from "react";
import classes from "./Signup.module.css";
import axios from "axios";
// import { BrowserRouter as Redirect, Link, Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import gif from "../../assets/signup-login.gif";
import "react-toastify/dist/ReactToastify.css";

import {
  Col,
  Input,
  Container,
  Label,
  Row,
  textarea,
  UncontrolledDropdown,
} from "reactstrap";

const Login = () => {
  const [emailId, setEmailId] = useState("");
  const [password, setPassword] = useState("");
  const [err, setErr] = useState(null);
  // const [pageChange, setPageChange] =useState(null);
  const navigate = useNavigate();
  const submitHandler = (event) => {
    console.log("button click");
    event.preventDefault();
    const signupData = {
      email: emailId,
      password: password,
    };
    axios
      .post("https://api.imo-india.org/school/login", signupData)
      .then((response) => {
        if (response.status == 200) {
          // console.log(response);
          toast.success(response.data.message);
          // setPageChange(true);
          localStorage.setItem("profile-image", response.data.profileImage);
          localStorage.setItem("token", response.data.token);
          // console.log(localStorage);
          navigate("/school/home");
        }
        // console.log(response);
      })
      .catch((error) => {
        // setPageChange(false);
        // console.log(error);
        toast.error(error.response.data.message);
        console.log(error.response.data.message);
        // setErr(error.response.data.message);
      });
  };

  return (
    <React.Fragment>
      <div className={classes.page}>
        <div className={classes.pagehalf1}>
          <img src={gif} alt="" />
        </div>
        <div className={classes.pagehalf2}>
          <div>
            <h2>
              Hi, <br /> Welcome
            </h2>
            <p>Kindly LogIn to your account</p>
            <br />
            <Row>
              <Col xxl={6} md={12} className="mt-3">
                <div>
                  <Label htmlFor="email" className="form-label">
                    <h2 className="zmdi zmdi-email material-icon-name" />
                    Email ID
                  </Label>
                  <Input
                    value={emailId}
                    onChange={(e) => {
                      setEmailId(e.target.value);
                    }}
                    // defaultValue={1}
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                    autoComplete="off"
                    // id="placeholderInput"
                    // placeholder=""
                  />
                </div>
              </Col>
              <Col xxl={6} md={12} className="mt-3">
                <div>
                  <Label htmlFor="password">
                    <h2 className="zmdi zmdi-lock material-icon-name" />
                    Password
                  </Label>
                  <Input
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    // defaultValue={1}
                    type="password"
                    name="password"
                    id="password"
                    className="form-control"
                    autoComplete="off"
                    // id="placeholderInput"
                    // placeholder=""
                  />
                </div>
              </Col>
              <Col xxl={4} md={6} className={classes.signupButton}>
                {/* {pageChange? <Link to="/school/home">:""} */}
                {/* <Link to="/school/home"> */}
                <button
                  className="btn btn-success"
                  // type="submit"
                  // onClick={(e) => onFormCreateSubmit(e)}
                  onClick={submitHandler}
                >
                  Log-In <h2 className="zmdi zmdi-mail-send" />
                </button>
                {/* </Link> */}
              </Col>
              <Col xxl={6} md={12}>
                <p id={classes.errorMessage}>{err}</p>
              </Col>
              <Col xxl={6} md={12} className={classes.pageChangeBelowButton}>
                <p>Haven't Registered your School yet? </p>{" "}
                <Link to="/school/sign-up">
                  <p id={classes.alreadyLogged}>Register</p>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </React.Fragment>
  );
};

export default Login;
