import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import map from "../../assets/map.svg";
import Arrow from "../../assets/Arrow.svg";
import location from "../../assets/location.svg";
import getInTouch from "../../assets/getInTouch.svg";
import { Link } from "react-router-dom";
import "./Contact.css";

const ContactUsPage = () => {
  return (
    <>
      <Header />
      <div className="contact_page">
        <div>
          <h2>Contact Us</h2>
          <h1>We’d love to hear from you</h1>
          <p style={{ color: "#ABABAB" }}>
            We have team and offices to assist you in need{" "}
          </p>
        </div>
        <br />
        <div>
          <img src={map} alt="" />
        </div>
        <br />
        <div className="contact_section">
          <span className="yellow__base"></span>
          <div>
            <i class="fa-sharp fa-solid fa-envelope"></i>
            <p>Our team is there to help you</p>
            <Link>imo@gmail.com</Link>
          </div>
          <span className="sep_line"></span>
          <div>
            <i class="fa-sharp fa-solid fa-envelope"></i>
            <p>Visit our Website</p>
            <Link>www.imo.org</Link>
          </div>
          <span className="sep_line"></span>
          <div>
            <i class="fa-sharp fa-solid fa-envelope"></i>
            <p>Mon - Fri from 9am to 5 pm </p>
            <Link>+91 9953-245-945</Link>
          </div>
          <span className="yellow__base"></span>
        </div>
        <div className="address">
          <Link to="/">
            <button>
              Go to google Maps{" "}
              <i class="fa-solid fa-circle-arrow-right white-bg"></i>
            </button>
          </Link>
          <img src={location} alt="" />
        </div>
        <br />
        <div className="contact_form">
          <form action="/contact">
            <span className="yellow__base orange_base"></span>
            <div className="form_details">
              <h1>GET IN TOUCH!</h1>
              <h6>Fill up the form and our team will get in touch with you</h6>

              <div className="form_details_enter">
                <div>
                  <label htmlFor="name">Name</label>
                  <br />
                  <input type="text" for="name" placeholder="name" />
                </div>
                <div>
                  <label htmlFor="name">Email</label>
                  <br />
                  <input type="text" for="email" placeholder="abc@gmail.com" />
                </div>
                <div>
                  <label htmlFor="name">Phone Number</label>
                  <br />
                  <input type="text" for="phone" placeholder="+91 9900xx0000" />
                </div>
                <div>
                  <label htmlFor="name">Message</label>
                  <br />
                  <input
                    type="text"
                    for="message"
                    placeholder="My query is ________"
                  />
                </div>

                <button className="send_message">
                  Send Message <img src={Arrow} alt="" />
                </button>
              </div>
            </div>
            <div className="getInTouch">
              <img src={getInTouch} alt="" />
            </div>
          </form>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ContactUsPage;
