import React from "react";
import "./whyUs.css";
import whyUs1 from "../../assets/whyUs-1.svg";
import whyUs2 from "../../assets/whyUs-2.svg";
import whyUs3 from "../../assets/whyUs-3.svg";
import whyUs4 from "../../assets/whyUs-4.svg";
import { Button, Image } from "react-bootstrap";
// import landingAbout from "../../assets/landingAbout.svg";
import landingPar from "../../assets/landingPar.svg";
import arrow from "../../assets/Arrow.svg";
import { Link } from "react-router-dom";
const whyUs = () => {
  return (
    <div className="about-us">
      {/* <span
        style={{
          position: "relative",
          left: "120px",
          top: "30px",
          width: "6px",
          height: "26px",
          backgroundColor: "red",
          display: "block",
        }}
      ></span>{" "} */}
      {/* <h3>Why Us?</h3> */}
      <div
        style={{
          backgroundColor: "#1D3F2E",
          position: "relative",
          height: "25vh",
          top: "199px",
          zIndex: "-1",
        }}
      ></div>
      <div style={{ color: "#F9CB6D" }} className="whyus-main">
        <div>
          <img src={whyUs1} alt="" />
          <p>Improving problem-solving skills</p>
        </div>
        <div>
          <img src={whyUs2} alt="" />
          <p>Building confidence</p>
        </div>
        <div>
          <img src={whyUs3} alt="" />
          <p>Exposure to advanced math concepts</p>
        </div>
        <div>
          <img src={whyUs4} alt="" />
          <p>Opportunities for recognition</p>
        </div>
      </div>
      <div className="par-landing">
        <div>
          <Image src={landingPar}></Image>
        </div>
        <div>
          <h2 style={{ fontWeight: "bold" }}>How to Participate?</h2>
          <p>
            The International Mathematical Olympiad (IMO) offers the opportunity
            for students to participate through their schools. If you are
            interested in joining or taking part in the IMO exams, it is
            recommended to share the official website of IMO with your school.
            The website contains detailed information about the registration
            process, eligibility criteria, exam dates, and study materials. By
            sharing the website with your school, you can ensure that they are
            aware of your interest in participating in the IMO and can provide
            you with the necessary guidance and support. Participating in the
            IMO can be a rewarding experience, as it allows you to compete with
            talented students from around the world and showcase your
            mathematical skills on an international stage. So, don't forget to
            inform your school about your interest in the IMO and share the
            website with them.
          </p>
          <a href="/participate">
            <Button style={{ backgroundColor: "#1D3F2E" }} variant="success">
              Know more <Image src={arrow}></Image>{" "}
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default whyUs;
