import React, { useState, useMemo } from "react";
import SideBar from "./SideBar.js";
import Header from "./Header.jsx";
import greetImg from "../../assets/greetImg.svg";
import stateTrophy from "../../assets/stateTrophy.svg";
import internationalTrophy from "../../assets/internationalTrophy.svg";
import prompt from "../../assets/singing-contract.svg";
import classes from "./HomePage.module.css";
import {
  getMonthDetail,
  getDateDay,
  getTodayData,
  getPrevYearMonth,
  getNextYearMonth,
  week,
} from "./utils.js";
import MonthWrapper from "./MonthWrapper";

const HomePage = () => {
  const [today, setToday] = useState(getTodayData());
  const [year, setYear] = useState(today.year);
  const [month, setMonth] = useState(today.month);

  const todayStr = useMemo(() => {
    return Object.values(today).join("");
  }, [today]);

  const setYearMonth = (data) => {
    setYear(data.year);
    setMonth(data.month);
  };
  const goPrev = () => {
    setYearMonth(getPrevYearMonth({ year, month }));
  };
  const goNext = () => {
    setYearMonth(getNextYearMonth({ year, month }));
  };
  const goToday = () => {
    setYearMonth(today);
  };
  return (
    <div>
      <div>
        <SideBar />
      </div>
      <div>
        <Header />
        <div className={classes.mainHome}>
          <div className={classes.infoHome}>
            <div className={classes.greetHome}>
              <div>
                <h2>Hello Saksham</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Officia non consequuntur quisquam placeat sed inventore,
                  libero suscipit
                </p>
              </div>
              <img src={greetImg} alt="" />
            </div>
            <div className={classes.mainRanking}>
              <h2>Your Rank</h2>
              <div className={classes.rankings}>
                <div className={classes.state}>
                  <img src={stateTrophy} alt="" />
                  <div>
                    <h3>170</h3>
                    <h5>State Level</h5>
                  </div>
                </div>
                <div className={classes.international}>
                  <img src={internationalTrophy} alt="" />
                  <div>
                    <h3>1,567</h3>
                    <h5>International Level</h5>
                  </div>
                </div>
                <div className={classes.school}>
                  <img src={stateTrophy} alt="" />
                  <div>
                    <h3>100</h3>
                    <h5>School Level</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.prompt}>
              <img src={prompt} alt="" />
              <p>Don’t forget to prepare for your exams!</p>
            </div>
          </div>
          <div className={classes.mainCal}>
            <MonthWrapper
              className={classes.calender}
              goPrev={goPrev}
              goNext={goNext}
              goToday={goToday}
              year={year}
              month={month}
              todayStr={todayStr}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
