import React, { useState } from "react";
import "./Dropdown.css";

// const options = ["one", "two", "three"];
// const defaultOption = options[0];
const DropDown = ({ setClass }) => {
  // const [value, setValue] = useState("Select Class");
  const classHandler = (e) => {
    // setClass(e.target.value);
  };
  return (
    <div>
      <select className="selectClass" onChange={classHandler}>
        <option selected disabled hidden value="SelectClass">
          Select Class
        </option>
        <option value="class1">Class 1</option>
        <option value="class2">Class 2</option>
        <option value="class3">Class 3</option>
        <option value="class4">Class 4</option>
        <option value="class5">Class 5</option>
        <option value="class6">Class 6</option>
        <option value="class7">Class 7</option>
        <option value="class8">Class 8</option>
        <option value="class9">Class 9</option>
        <option value="class10">Class 10</option>
        <option value="class11">Class 11</option>
        <option value="class12">Class 12</option>
      </select>
    </div>
  );
};

export default DropDown;
