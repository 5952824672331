import React from "react";
import question from "../../assets/question.svg";
import { Link } from "react-router-dom";
import "./Question.css";

const Questions = () => {
  return (
    <div style={{ padding: "10px 140px" }}>
      <div className="question">
        <div className="ques-details">
          <p>
            Ask about bla consequat duis enim velit mollit. Exercitation veniam
            consequat sunt nostrud amet.”{" "}
          </p>
          <div className="call-det">
            <Link to="/contact">
              <button>Contact Us > </button>
            </Link>
            <span>or call +91 9953-245-945</span>
          </div>
        </div>
        <img src={question} alt="" />
      </div>
    </div>
  );
};

export default Questions;
