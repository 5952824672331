import React from "react";
import SideBar from "./SideBar.js";
import "./AdmitCard.css";
import icard from "../../assets/icard.svg";
import Header from "./Header.jsx";

const AdmitCard = () => {
  return (
    <div>
      <div>
        <SideBar />
      </div>
      <div className="bg-support">
        <Header />
        <div className="i-card-ele">
          <img src={icard} alt="" />
        </div>
      </div>
    </div>
  );
};

export default AdmitCard;
