import React from "react";
import { Fragment } from "react";
import MOF_LOGO from "../../assets/mof_logo.svg";
import { Link } from "react-router-dom";
import classes from "./Header.module.css";

const Header = () => {
  return (
    <Fragment>
      <div className={classes.main}>
        <div className={classes.mof_logo}>
          <Link to="/">
            <img src={MOF_LOGO} alt="" />
          </Link>
        </div>
        <div className={classes.options}>
          <ul>
            <Link to="/">
              <li>Home</li>
            </Link>
            <Link to="/about">
              <li>About</li>
            </Link>
            <Link to="/toppers">
              <li>Toppers</li>
            </Link>
            <div>
              <div className={classes.dropdown}>
                <Link className={classes.dropbtn}>
                  <li>
                    Learn
                    <i
                      style={{ marginLeft: "4px", color: "black" }}
                      class="fa fa-caret-down"
                    ></i>
                  </li>
                </Link>
                <div className={classes.dropdown_content}>
                  <Link to="/learn">Tutorials</Link>
                  <Link to="/learn">Mock Test Paper</Link>
                  <Link to="/about">Syllabus</Link>
                </div>
              </div>
              {/* <Link to="/learn">
                <li className={classes.learn}>Learn</li>
              </Link> */}
            </div>

            <Link to="/contact">
              <li>Contact Us</li>
            </Link>
          </ul>
        </div>
        <div className={classes.btnRegCon}>
          <Link to="/school/log-in">
            <button className={classes.regBtn}>School Registration</button>
          </Link>
          <Link to="/student/home">
            <button className={classes.conBtn}>Student Login</button>
          </Link>
        </div>
      </div>
    </Fragment>
  );
};

export default Header;
