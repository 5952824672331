import React, { useState, useMemo, useEffect } from "react";
import SideBar from "./SideBar.js";
import MailSuccessImage from "../../assets/successRegPopUp.svg";
import Header from "./Header.jsx";
import classes from "./SchoolHome.module.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  Row,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledDropdown,
} from "reactstrap";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { alignPropType } from "react-bootstrap/esm/types.js";

const SchoolHome = () => {
  const [checkmodal, setCheckmodal] = useState(false);
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [isSelected1, setIsSelected1] = useState(false);
  const [principalName, setPrincipalName] = useState("");
  const [principalEmailId, setPrincipalEmailId] = useState("");
  const [principalPhoneNumber, setPrincipalPhoneNumber] = useState("");
  const [coordinatorName, setCoordinatorName] = useState("");
  const [coordinatorEmailId, setCoordinatorEmailId] = useState("");
  const [coordinatorPhoneNumber, setCoordinatorPhoneNumber] = useState("");

  const [alreadyRegd, setAlreadyRegd] = useState(true);
  const toggle = () => setModal(!modal);
  const toggle1 = () => setModal1(!modal1);
  const toggle2 = () => setModal2(!modal2);
  const toggle3 = () => setModal3(!modal3);
  // const toggle2 = () => setAlreadyRegd(!alreadyRegd);
  useEffect(() => {
    if (checkmodal === false) {
      axios
        .get("https://api.imo-india.org/school/details", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          if (res.status == 200) {
            console.log(res);
            if (res.data.coordinatorEmail != null) {
              setCheckmodal(true);
              setModal2(false);
            } else {
              setModal2(true);
              setCheckmodal(false);
            }
          }
        })
        .catch((err) => {
          setCheckmodal(false);
          setModal2(true);
          console.log(err);
        });
    }
  }, [checkmodal]);

  const onFormSubmit = (e) => {
    e.preventDefault();
    const signupData = {
      principalName: principalName,
      principalPhoneNumber: principalPhoneNumber,
      principalEmail: principalEmailId,
      coordinatorName: coordinatorName,
      coordinatorPhoneNumber: coordinatorPhoneNumber,
      coordinatorEmail: coordinatorEmailId,
    };
    axios
      .put("https://api.imo-india.org/school/update-details", signupData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        // console.log(res);
        // event.target.reset();
        if (res.status == 200) {
          toast.success(res.data.message);
          // toggle2();
          setModal2(false);
          setModal3(true);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
        setCheckmodal(false);
      });
  };
  const handlesubmit = (event) => {
    event.preventDefault();
    let data = {};
    axios
      .put(
        "https://api.imo-india.org/school/exams/receive-question-paper",
        data,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setIsSelected(true);
        toggle();
        toast.success(res.response.data.message);
        console.log(res);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error);
      });
  };
  const handlesubmit1 = (e) => {
    e.preventDefault();
    let data = {};
    axios
      .put("https://api.imo-india.org/school/exams/send-answer-sheet", data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setIsSelected1(true);
        toggle1();
        toast.success(res.response.data.message);
        console.log(res);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error);
      });
  };
  return (
    <React.Fragment>
      <div>
        <div>
          <SideBar />
        </div>
        <div>
          <Header />
          <div className={classes.mainHome}>
            <div className={classes.infoHome}>
              <div className={classes.greetHome}>
                <div>
                  {/* <h2>Hello Saksham</h2>
      <div>
        <div>
          <SideBar />
        </div>
        <div>
          <Header />
          <div className={classes.mainHome}>
            <div className={classes.infoHome}>
              <div className={classes.greetHome}>
                <div>
                  {/* <h2>Hello Saksham</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Officia non consequuntur quisquam placeat sed inventore,
                  libero suscipit
                </p> */}
                </div>
                {/* <img src={greetImg} alt="" /> */}
              </div>
              <div className={classes.mainRanking}>
                {/* <h2>Your Rank</h2> */}
                <div className={classes.rankings}>
                  <div className={classes.formCheckSwitch}>
                    <div className={classes.fromCheckLeft}>
                      <Label className="form-check-label" for="isReceived">
                        Question Paper Received
                      </Label>
                      <p>
                        By ticking this, you are assuring IMO that the school
                        has received Question Paper.
                      </p>
                    </div>
                    <div className={classes.fromCheckRight}>
                      <Input
                        onClick={toggle}
                        checked={isSelected}
                        className="custom-toggle custom-toggle-success"
                        type="checkbox"
                        id="isReceived"
                      />
                    </div>
                  </div>
                  <div className={classes.formCheckSwitch}>
                    <div className={classes.fromCheckLeft}>
                      <Label className="form-check-label" for="isReceived">
                        Answer Sheets Sent
                      </Label>
                      <p>
                        By ticking this, you are assuring IMO that the school
                        has sent Answer Sheets.
                      </p>
                    </div>
                    <div className={classes.fromCheckRight}>
                      <Input
                        onClick={toggle}
                        checked={isSelected}
                        className="custom-toggle custom-toggle-success"
                        type="checkbox"
                        id="isReceived"
                      />
                    </div>
                  </div>
                </div>
                <Modal
                  size="md"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  isOpen={modal}
                  toggle={toggle}
                >
                  <ModalHeader toggle={toggle}>Send Confirmation</ModalHeader>
                  <ModalBody>Do you wanna confirm?</ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={handlesubmit}>
                      Yes
                    </Button>{" "}
                    <Button color="secondary" onClick={toggle}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
                <Modal
                  size="md"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  isOpen={modal1}
                  toggle={toggle1}
                >
                  <ModalHeader toggle={toggle1}>Send Confirmation</ModalHeader>
                  <ModalBody>Do you wanna confirm?</ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={handlesubmit1}>
                      Yes
                    </Button>{" "}
                    <Button color="secondary" onClick={toggle1}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>
              <div className={classes.prompt}>
                {/* <img src={prompt} alt="" /> */}
                {/* <p>Don’t forget to prepare for your exams!</p> */}
              </div>
            </div>
            <div className={classes.mainCa}>
              <Calendar />
            </div>
          </div>
        </div>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          // className="modal-md"
          // centered
          backdrop="static"
          fade
          scrollable
          className={classes.ModalModify}
          isOpen={modal2}
          // isOpen={true}
          toggle={toggle2}
        >
          <ModalHeader>Complete the form</ModalHeader>
          <ModalBody>
            <form className={classes.forForm} onSubmit={(e) => onFormSubmit(e)}>
              <Row className={classes.formInside}>
                <Col md={6}>
                  <div>
                    <Label htmlFor="labelInput" className="form-label">
                      Principal's Name
                    </Label>
                    <Input
                      value={principalName}
                      onChange={(e) => {
                        setPrincipalName(e.target.value);
                      }}
                      type="text"
                      className="form-control"
                      id="labelInput"
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div>
                    <Label htmlFor="labelInput" className="form-label">
                      School Olympiad Coordinator
                    </Label>
                    <Input
                      value={coordinatorName}
                      onChange={(e) => {
                        setCoordinatorName(e.target.value);
                      }}
                      type="text"
                      className="form-control"
                      id="labelInput"
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div>
                    <Label htmlFor="labelInput" className="form-label">
                      Principal's Phone Number
                    </Label>
                    <Input
                      value={principalPhoneNumber}
                      onChange={(e) => {
                        setPrincipalPhoneNumber(e.target.value);
                      }}
                      type="text"
                      className="form-control"
                      id="labelInput"
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div>
                    <Label htmlFor="labelInput" className="form-label">
                      Coordinator Phone Number
                    </Label>
                    <Input
                      value={coordinatorPhoneNumber}
                      onChange={(e) => {
                        setCoordinatorPhoneNumber(e.target.value);
                      }}
                      type="text"
                      className="form-control"
                      id="labelInput"
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div>
                    <Label htmlFor="labelInput" className="form-label">
                      Principal's Email ID
                    </Label>
                    <Input
                      value={principalEmailId}
                      onChange={(e) => {
                        setPrincipalEmailId(e.target.value);
                      }}
                      type="text"
                      className="form-control"
                      id="labelInput"
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div>
                    <Label htmlFor="labelInput" className="form-label">
                      Coordinator Email ID
                    </Label>
                    <Input
                      value={coordinatorEmailId}
                      onChange={(e) => {
                        setCoordinatorEmailId(e.target.value);
                      }}
                      type="text"
                      className="form-control"
                      id="labelInput"
                    />
                  </div>
                </Col>
                <Col sm={12} className={classes.signupButton}>
                  <Button
                    className="btn btn-success"
                    // onClick={onFormSubmit}
                  >
                    Completed {"  "}
                    <h2 className="zmdi zmdi-mail-send" />
                  </Button>
                </Col>
              </Row>
            </form>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          // className="modal-md"
          // centered
          backdrop
          fade
          className={classes.ModalModify}
          isOpen={modal3}
          // isOpen={true}
          toggle={toggle3}
        >
          <ModalHeader toggle={toggle3} />
          <ModalBody className={classes.ModalBodyModify}>
            <img src={MailSuccessImage} alt="mail" />
            <p>
              We've just sent a confirmation email to the address you provided.
            </p>
          </ModalBody>
        </Modal>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </React.Fragment>
  );
};

export default SchoolHome;
