import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ToppersImg from "../../assets/Toppers.svg";
import classes from "./Toppers.module.css";
import { randomBytes } from "crypto";

const ToppersPage = () => {
  const [classValue, setClassValue] = useState(0);
  // const data = [
  //   {
  //     id: randomBytes(4).toString("hex"),
  //     name: "udit",
  //     class: "1",
  //     school: "abc school",
  //   },
  //   {
  //     id: randomBytes(4).toString("hex"),
  //     name: "udit",
  //     class: "1",
  //     school: "abc school",
  //   },
  //   {
  //     id: randomBytes(4).toString("hex"),
  //     name: "udit",
  //     class: "1",
  //     school: "abc school",
  //   },
  //   {
  //     id: randomBytes(4).toString("hex"),
  //     name: "udit",
  //     class: "2",
  //     school: "abc school",
  //   },
  //   {
  //     id: randomBytes(4).toString("hex"),
  //     name: "udit",
  //     class: "2",
  //     school: "abc school",
  //   },
  // ];

  return (
    <div>
      <Header />
      <div className={classes.topper}>
        <div>
          <h1>
            {" "}
            <span style={{ color: "#F47458" }}>KUDOS!</span>
            <br />
            <span style={{ fontSize: "4rem" }}>to our toppers of IMO 2023</span>
          </h1>
        </div>
        <div>
          <img src={ToppersImg} alt="" />
        </div>
      </div>
      <br />
      <br />
      <div style={{ textAlign: "center" }}>
        <h1>
          We have <span style={{ color: "#21794B" }}>1k + SCHOOLS</span>{" "}
          partnered with Us
        </h1>
        <h5>The International Mathematical Olympiad (IMO) is the World </h5>
      </div>
      <br />
      <br />
      <div className={classes.topperTable}>
        <table class="table table-hover">
          <thead style={{ borderRadius: "10px" }} class="table-success">
            <tr>
              <th scope="col">Name</th>
              <th scope="col">School</th>
              <th scope="col">Class </th>
              <th scope="col">Roll No</th>
              <th scope="col">Rank</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Anaya Singhal</td>
              <td>Rukmini Devi Public School Pitampura</td>
              <td>3</td>
              <td>31685</td>
              <td>1st</td>
            </tr>
            <tr>
              <td>Shanaya</td>
              <td>Lancer’s Convent Sr. Sec. School Prashant Vihar</td>
              <td>4</td>
              <td>41502</td>
              <td>1st</td>
            </tr>
            <tr>
              <td>Moksha Neill</td>
              <td>Somerville School Vasundhara Enclave</td>
              <td>5</td>
              <td>50648</td>
              <td>1st</td>
            </tr>
            <tr>
              <td>Hrucha Partani</td>
              <td>Delhi Public School Bengaluru North</td>
              <td>6</td>
              <td>60480</td>
              <td>1st</td>
            </tr>
            <tr>
              <td>Bibek Ranjan Muduli</td>
              <td>Jawahar Navodaya Vidyalaya Cuttack</td>
              <td>7</td>
              <td>70725</td>
              <td>1st</td>
            </tr>
            <tr>
              <td>Akshat Gupta</td>
              <td>Rukmini Devi Public School Pitampura</td>
              <td>8</td>
              <td>81153</td>
              <td>1st</td>
            </tr>
            <tr>
              <td>Sarthak Sahu</td>
              <td>Ideal HS School Bagh Bhopal</td>
              <td>9</td>
              <td>90055</td>
              <td>1st</td>
            </tr>
            <tr>
              <td>Ayush Singh</td>
              <td>Saint Hood Convent School Dadri</td>
              <td>10</td>
              <td>11361</td>
              <td>1st</td>
            </tr>
            <tr>
              <td>Mitashi Dua</td>
              <td>Rukmini Devi Public School Pitampura</td>
              <td>11</td>
              <td>1648</td>
              <td>1st</td>
            </tr>
            <tr>
              <td>Somya Jangid</td>
              <td>Kendriya Vidyalaya No.1 Bajaj Nagar Jaipur</td>
              <td>12</td>
              <td>1246</td>
              <td>1st</td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      {/* <div style={{ textAlign: "center" }}>
        <p style={{ textTransform: "uppercase" }}>Can’nt find your name ?</p>
        <h1>TOP 10 TOPPERS</h1>
      </div>
      <div className={classes.classNa}>
        <button
          onClick={(e) => {
            setClassValue(1);
          }}
        >
          Class - 1
        </button>
        <button
          onClick={(e) => {
            setClassValue(2);
          }}
        >
          Class - 2
        </button>
        <button
          onClick={(e) => {
            setClassValue(3);
          }}
        >
          Class - 3
        </button>
        <button
          onClick={(e) => {
            setClassValue(4);
          }}
        >
          Class - 4
        </button>
        <button
          onClick={(e) => {
            setClassValue(5);
          }}
        >
          Class - 5
        </button>
        <button
          onClick={(e) => {
            setClassValue(6);
          }}
        >
          Class - 6
        </button>
        <button
          onClick={(e) => {
            setClassValue(7);
          }}
        >
          Class - 7
        </button>
        <button
          onClick={(e) => {
            setClassValue(8);
          }}
        >
          Class - 8
        </button>
        <button
          onClick={(e) => {
            setClassValue(9);
          }}
        >
          Class - 9
        </button>
        <button
          onClick={(e) => {
            setClassValue(10);
          }}
        >
          Class - 10
        </button>
        <button
          onClick={(e) => {
            setClassValue(11);
          }}
        >
          Class - 11
        </button>
        <button
          onClick={(e) => {
            setClassValue(12);
          }}
        >
          Class - 12
        </button>
      </div> */}
      <Footer />
    </div>
  );
};

export default ToppersPage;
