import React, { useState, useEffect } from "react";
import Home from "../../assets/home.svg";
import admitCard from "../../assets/admitCard.svg";
import myResults from "../../assets/myResults.svg";
// import tutorials from "../../assets/tutorials.svg";
// import certi from "../../assets/certi.svg";
import set from "../../assets/set.svg";
import mof_logo from "../../assets/mof_logo.svg";
import classes from "./SideBar.module.css";
import { Link } from "react-router-dom";

const SideBar = () => {
  // class toggle
  const [toggle, setToggle] = useState(false);
  // console.log(toggle);
  const toggleClassHandler = (e) => {
    setToggle(!toggle);
  };
  useEffect(() => {}, [toggle]);
  return (
    <div>
      <div className={classes.sidebar}>
        <div className={classes.mof}>
          <Link to="/student/home">
            <img src={mof_logo} alt="" />
          </Link>
        </div>
        <div className={classes.sideBarTxt}>
          <ul>
            <Link to="/school/home">
              <li>
                <img className={classes.sideBarIcon} src={Home} alt="" /> Home
              </li>
            </Link>
            <Link to="/school/student-data">
              <li>
                <img className={classes.sideBarIcon} src={myResults} alt="" />{" "}
                Enrollment Data
              </li>
            </Link>
            <Link to="/school/upload-list">
              <li>
                <img className={classes.sideBarIcon} src={admitCard} alt="" />{" "}
                Upload List
              </li>
            </Link>
            {/* <Link to="/school/details">
              <li>
                <img className={classes.sideBarIcon} src={myResults} alt="" />{" "}
                Details
              </li>
            </Link> */}
            <Link to="/school/sheets">
              <li>
                <img className={classes.sideBarIcon} src={admitCard} alt="" />{" "}
                Sheets
              </li>
            </Link>
            <Link to="/school/payment-slip">
              <li>
                <img className={classes.sideBarIcon} src={myResults} alt="" />{" "}
                Payment Slip
              </li>
            </Link>
            <Link to="/school/settings">
              <li>
                <img className={classes.sideBarIcon} src={set} alt="" />{" "}
                Settings
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
